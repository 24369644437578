<template>
  <div>
    <b-form>
      <b-card>

        <b-overlay
          v-if="isWorking"
          :show="isWorking"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div
              v-if="isWorking"
              class="mt-2"
            >
              <div style="text-align: center">
                <span class="spinner-border " />
              </div>
              <div style="text-align: center">
                <small>{{ overlayConfig.message }}</small>
              </div>
            </div>
          </template>
        </b-overlay>
        <b-row>

          <b-col
            md="10"
            class="m-0"
          >
            <h4>
              {{ title }}
            </h4>
          </b-col>
          <b-col md="2">
            <div
              style="float: right;"
              class="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <b-button
                v-if="permisosUpd.includes(nameModulo) && showBtnEdit"
                id="btnEditUsuario"
                v-b-tooltip.hover
                variant="light"
                squared
                title="Editar usuarios"
                data-toggle="button"
                aria-pressed="true"
                autocomplete="on"
                size="sm"
                :disabled="idUsuario === undefined"
                @click="editUsuario()"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <!--<b-button
            v-if="permisosDel.includes(nameModulo) && usuarioDto.usuarioEstado.nombre === 'Activo'"
            id="btnDesactivarUsuario"
            v-b-tooltip.hover
            variant="danger"
            squared
            size="sm"
            title="Desactivar usuario"
            :disabled="idUsuario ===undefined"
            @click="desactivarUsuario()"
          >
            <font-awesome-icon icon="fa-solid fa-ban" />
          </b-button>
          <b-button
            v-if="permisosDel.includes(nameModulo) && usuarioDto.usuarioEstado.nombre === 'Inactivo'"
            id="btnReactivarUsuario"
            v-b-tooltip.hover
            variant="primary"
            squared
            size="sm"
            title="Reactivar usuario"
            :disabled="idUsuario ===undefined"
            @click="reactivarUsuario()"
          >
            <font-awesome-icon icon="fa-solid fa-play" />
          </b-button>-->
            </div>
          </b-col>
        </b-row>

        <usuario-generico
          v-if="usuarioDto.id || openMode === 'new'"
          :usuario="usuarioDto"
          :editar-enable-disable="editar"
          :open-mode="openMode"
        />
      </b-card>
    </b-form>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { usuariosResource } from '@/services/usuariosResource'
import UsuarioGenerico from './UsuarioGenerico.vue'

export default {
  components: {
    UsuarioGenerico,
  },
  props: {
    idUsuario: {
      type: String,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      isWorking: false,
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.5',
        blur: '5px',
        message: '',
      },
      nameModulo: 'ABM_USUARIO',
      editar: false,
      usuarioDto: {
        id: null,
        apellido: '',
        nombre: '',
        cuil: '',
        email: null,
        fechaNacimiento: null,
        fechaIngreso: null,
        fechaBaja: null,
        baja: null,
        delegacion: {
          id: null,
          nombre: null,
        },
        usuarioEstado: {
          id: 1,
          nombre: null,
        },
        roles: [],
      },
    }
  },
  computed: {
    showBtnEdit() {
      let show = false
      if (this.openMode === 'view') {
        show = true
      }
      return show
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    localStorage.setItem('lastPath', this.$route.path)
    this.findUsuario(this.idUsuario)
    this.setPageTitle()
  },
  methods: {
    editUsuario() {
      if (this.editar) {
        this.editar = false
        this.title = 'Usuario'
      } else {
        this.editar = true
        this.title = 'Edición de usuario'
      }
    },
    setPageTitle() {
      if (this.openMode === 'edit') {
        this.title = 'Edición de usuario'
      } else if (this.openMode === 'view') {
        this.title = 'Usuario'
      } else {
        this.title = 'Nuevo Usuario'
      }
    },
    findUsuario(idUsuario) {
      this.isWorking = true
      this.overlayConfig.message = 'Recuperando datos de usuario'
      if (idUsuario !== undefined) {
        usuariosResource().findByIdUsuario(idUsuario)
          .then(resultsFindByIdUsuarios => {
            this.usuarioDto = { ...resultsFindByIdUsuarios }
          }).catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.isWorking = false
          })
      } else {
        // this.title = 'Nuevo usuario'
        this.isWorking = false
      }
    },
  },
}
</script>
