import axios from 'axios'
import {
  getByIdUsuario,
  postByUsuarioByFilter,
  getByUsuarioBySpecs,
  putActivarUsuario,
  putDesactivarUsuario,
  postSaveUsuario,
  putUpdateUsuario,
} from '@/utils/paths'
// import { removeAccentsFromString } from '@/utils/stringUtils'

const pathGetByIdUsuario = getByIdUsuario
const pathPostByUsuarioByFilter = postByUsuarioByFilter
const pathGetByUsuarioBySpecs = getByUsuarioBySpecs
const pathPutActivarUsuario = putActivarUsuario
const pathPutDesactivarUsuario = putDesactivarUsuario
const pathPostSaveUsuario = postSaveUsuario
const pathPutUpdateUsuario = putUpdateUsuario

export function usuariosResource() {
  function findByIdUsuario(idUsuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIdUsuario + idUsuario)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  function getByUsuarioByFilter(filter, page) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${pathPostByUsuarioByFilter
          }?page=${
            page.page
          }&size=${
            page.size
          }&sort=${
            page.sort}`,
          filter,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getByFilterUsuarioSpecs(filter, page) {
    let filterDto = ''
    if (filter) {
      filterDto = `&search=(nombre:'*${
        // removeAccentsFromString(filter)
        filter
      }*' OR apellido:'*${
        // removeAccentsFromString(filter)
        filter
      }*' OR cuil:'*${
        // removeAccentsFromString(filter)
        filter
      }*' OR email:'*${
        // removeAccentsFromString(filter)
        filter
      }*')`
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${pathGetByUsuarioBySpecs
        }?page=${
          page.page
        }&size=${
          page.size
        }&sort=${
          page.sort
        }${filterDto}`)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function activarUsuario(idUsuario) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutActivarUsuario + idUsuario)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function desactivarUsuario(idUsuario) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutDesactivarUsuario + idUsuario)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveUsuario(usuariosDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveUsuario, usuariosDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateUsuario(usuariosDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateUsuario + usuariosDto.id, usuariosDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findByIdUsuario,
    getByUsuarioByFilter,
    getByFilterUsuarioSpecs,
    activarUsuario,
    desactivarUsuario,
    saveUsuario,
    updateUsuario,
  }
}
